import { theme } from '@chakra-ui/core';
import '../fonts/fonts.css';
const breakpoints = ['30em', '52em', '62em', '80em'];
export default {
  ...theme,
  breakpoints,
  colors: {
    ...theme.colors,
    brandPrimary: '#F8BD44',
    brandSecondary: '#FBE7B5',
    brandGrey: '#596678',
    brandGreyDark: '#5A5959',
    brandDark: '#1A1A1A'
  },
  fonts: {
    // body: 'system-ui, sans-serif',
    body: 'kohinoor-book',
    heading: 'oxygen-bold',
    mono: 'Menlo, monospace',
    graphikMedium: 'graphik-medium',
    graphikBold: 'graphik-bold',
    oxygenBold: 'oxygen-bold',
    oxygenRegular: 'oxygen-regular',
    openSansSemiBold: 'open-sans-semi-bold',
    openSansRegular: 'open-sans-regular',
    kohinoorBook: 'kohinoor-book',
    kohinoorMedium: 'kohinoor-medium',
    kohinoorDemi: 'kohinoor-demi'
  }
};
